<template>
  <div class="about">
    <h1>仅管理员可见</h1>
  </div>
  <div :style="{ marginTop: '20px' }">
    <a-button type="text" status="normal" size="mini" @click="handleClick"
      >回复详情
    </a-button>
  </div>
  <a-drawer
    :width="340"
    :height="340"
    :visible="visible"
    :placement="position"
    @ok="handleOk"
    @cancel="handleCancel"
    unmountOnClose
  >
    <template #title> Title</template>
    <div>
      You can customize modal body text by the current situation. This modal
      will be closed immediately once you press the OK button.
    </div>
  </a-drawer>
</template>

<script setup lang="ts">
import { ref } from "vue";

const visible = ref(false);
const position = ref("left");

const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};
</script>
