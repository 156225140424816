<template>
  <div id="listQuestionView">
    <a-form :model="searchParams" :layout="'inline'">
      <a-form-item field="title" label="题目名称">
        <a-input
          v-model="searchParams.title"
          placeholder="请输入题目名称"
          style="min-width: 240px"
        />
      </a-form-item>
      <a-form-item field="tags" label="题目标签">
        <a-input-tag
          v-model="searchParams.tags"
          placeholder="请输入题目标签"
          style="min-width: 280px"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSearch">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: total,
        showTotal: true,
      }"
      @page-change="onPageChange"
    >
      <template #tags="{ record }">
        <a-space wrap>
          <a-tag
            v-for="(tag, index) of record.tags"
            :key="index"
            :color="colors[tag]"
            >{{ tag }}
          </a-tag>
        </a-space>
      </template>
      <template #acceptRate="{ record }">
        {{
          `${
            record.submitNum
              ? ((record.acceptedNum * 100) / record.submitNum).toFixed(2)
              : "0"
          }% (${record.acceptedNum}/${record.submitNum})`
        }}
      </template>
      <template #createTime="{ record }"
        >{{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toSolveQuestion(record)"
            >做题
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";

import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generator";

const dataList = ref([]);
const total = ref(0);
let searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 10,
  current: 1,
});
const loadData = async () => {
  const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
    console.log(res.data);
  } else {
    message.error("加载失败 " + res.message);
  }
};
/**
 * 页面加载时获取题目数据
 */
onMounted(() => {
  loadData();
});

const colors = {
  简单: "green",
  适中: "orange",
  困难: "red",
};

const columns = [
  {
    title: "题号",
    dataIndex: "id",
  },
  {
    title: "题目名称",
    dataIndex: "title",
  },
  {
    title: "标签",
    slotName: "tags",
  },
  {
    title: "通过率",
    slotName: "acceptRate",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
/**
 * 做题
 */
const router = useRouter();
const toSolveQuestion = async (question: Question) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};

/**
 * 该函数监听其传入函数内属性值的变化，一旦发生变化就会重新触发该函数
 */
watchEffect(() => {
  loadData();
});

/**
 * 搜索题目
 */
const doSearch = () => {
  //先将当前页改为1,避免搜索后的数据显示不出来（比如搜索出来的数据可能没有第二页）
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
  //数据改变后就会触发loadData，因为它被监听了
};

//分页
const onPageChange = (current: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: current,
  };
};
</script>

<style scoped>
#listQuestionView {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
