<template>
  <Viewer :value="value" :plugins="plugins" />
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Viewer } from "@bytemd/vue-next";
import { ref, withDefaults, defineProps } from "vue";

//从编辑器组件复制过来，把必要的想获取值的代码删除，因为我们这里只是查看而已
interface Props {
  value: string;
}

const plugins = [gfm(), highlight()];

const props = withDefaults(defineProps<Props>(), {
  value: () => "",
});
</script>

<style>
/*将github的图标去除*/
.bytemd-toolbar-icon.bytemd-tippy.bytemd-tippy-right:last-child {
  display: none;
}
</style>
