/**
 *
 * @param loginUser 当前登录用户
 * @param needAccess 需要有的权限
 * @return boolean 有无权限
 */

import ACCESS_EMUM from "@/access/accessEnum";

const checkAccess = (loginUser: any, needAccess = ACCESS_EMUM.NOT_LOGIN) => {
  //needAccess默认值为不需要登陆
  //获取当前登录用户具有的权限(如果没有loginUser则表示未登录)
  const loginUserAccess = loginUser?.userRole ?? ACCESS_EMUM.NOT_LOGIN;
  //如果所访问的页面需要的权限不需要登陆,则放行
  if (needAccess === ACCESS_EMUM.NOT_LOGIN) {
    return true;
  }
  //如果需要登陆
  if (needAccess === ACCESS_EMUM.USER) {
    //如果用户没有登录，则不放行
    if (loginUserAccess === ACCESS_EMUM.NOT_LOGIN) {
      return false;
    }
  }
  //如果需要管理员权限
  if (needAccess === ACCESS_EMUM.ADMIN) {
    //如果此时登录的用户不是管理员，则不予放行
    if (loginUserAccess !== ACCESS_EMUM.ADMIN) {
      return false;
    }
  }
  //否则都放行
  return true;
};

export default checkAccess;
