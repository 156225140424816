/**
 * 权限定义
 **/

const ACCESS_EMUM = {
  NOT_LOGIN: "notLogin",
  USER: "user",
  ADMIN: "admin",
};

export default ACCESS_EMUM;
