<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <a-space>
          <img class="logo" src="../assets/mylogo.jpeg" />
          <div>My Oj</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">编程导航知识星球</a-layout-footer>
    </a-layout>
  </div>
</template>

<script lang="ts">
export default {
  name: "BasicLayout",
};
</script>

<style scoped>
#userLayout {
  text-align: center;
}

#userLayout .logo {
  height: 64px;
  width: 64px;
}

#userLayout .header {
  margin-top: 16px;
}

#userLayout .content {
  margin-bottom: 16px;
  padding: 20px;
}

#userLayout .footer {
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
