<template>
  <div class="about">
    <h1>你没有权限</h1>
  </div>
</template>
<script lang="ts">
export default {
  name: "NotAuthView",
};
</script>
