<template>
  <h2>用户登录</h2>
  <a-form
    style="max-width: 480px; margin: 0 auto"
    label-align="left"
    auto-label-width
    :model="form"
    :style="{ width: '600px' }"
    @submit="handleSubmit"
  >
    <a-form-item field="userAccount" tooltip="请输入账号" label="账号">
      <a-input v-model="form.userAccount" placeholder="请输入账号" />
    </a-form-item>
    <a-form-item field="userPassword" label="密码">
      <a-input-password v-model="form.userPassword" placeholder="请输入密码" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" shape="square" size="medium" html-type="submit"
        >提交
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script lang="ts" setup>
import { reactive } from "vue";

import { useRouter } from "vue-router";
import message from "@arco-design/web-vue/es/message";
import store from "@/store";
import { UserControllerService, UserLoginRequest } from "../../../generator";

const router = useRouter();

const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginRequest);

const handleSubmit = async () => {
  const res = await UserControllerService.userLoginUsingPost(form);
  if (res.code === 0) {
    message.success("登陆成功!");
    //获取到当前登录信息
    await store.dispatch("user/getLoginUser");
    //跳转到首页
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登陆失败!");
  }
};
</script>
