<template>
  <div id="addQuestionView">
    <h2>创建题目</h2>

    <a-form :model="form" label-align="left">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" placeholder="请选择标签" allow-clear />
      </a-form-item>
      <a-form-item field="content" label="题目内容">
        <MdEditor :value="form.content" :handle-change="onContentChange" />
      </a-form-item>
      <a-form-item field="answer" label="答案">
        <MdEditor :value="form.answer" :handle-change="onAnswerChange" />
      </a-form-item>
      <a-form-item label="判题设置" :content-flex="false" :merge-props="false">
        <a-space direction="vertical" style="min-width: 480px">
          <a-form-item field="judgeConfig.timeLimit" label="时间限制">
            <a-input-number
              mode="button"
              size="small"
              v-model="form.judgeConfig.timeLimit"
              placeholder="请输入时间限制"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
            <a-input-number
              mode="button"
              size="small"
              v-model="form.judgeConfig.stackLimit"
              placeholder="请输入堆栈限制"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
            <a-input-number
              mode="button"
              size="small"
              v-model="form.judgeConfig.memoryLimit"
              placeholder="请输入内存限制"
            />
          </a-form-item>
        </a-space>
      </a-form-item>
      <a-form-item label="判题用例" :content-flex="false" :merge-props="false">
        <a-space direction="vertical" style="min-width: 480px">
          <a-form-item
            v-for="(judgeCaseItem, index) of form.judgeCase"
            :key="index"
            no-style
          >
            <a-form-item
              :field="`form.judgeCase[${index}].input`"
              :label="`输入用例-${index}`"
              :key="index"
            >
              <a-input
                v-model="judgeCaseItem.input"
                placeholder="请输入测试输入用例"
              />
            </a-form-item>
            <a-form-item
              :field="`form.judgeCase[${index}].output`"
              :label="`输出用例-${index}`"
              :key="index"
            >
              <a-input
                v-model="judgeCaseItem.output"
                placeholder="请输入测试输出用例"
              />
            </a-form-item>
          </a-form-item>
        </a-space>
        <div style="margin-top: 16px">
          <a-button status="success" @click="handleAdd">添加测试用例</a-button>
          <a-button
            style="margin-left: 20px"
            status="danger"
            @click="handleDelete(index)"
            >删除
          </a-button>
        </div>
      </a-form-item>

      <a-form-item style="margin-top: 16px">
        <a-button @click="handleSubmit" type="primary" style="min-width: 100px"
          >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";

import message from "@arco-design/web-vue/es/message";
import { useRoute } from "vue-router";
import { on } from "@arco-design/web-vue/es/_utils/dom";

const form = ref({
  answer: "",
  content: "",
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
  judgeConfig: {
    memoryLimit: 0,
    stackLimit: 0,
    timeLimit: 0,
  },
  tags: [],
  title: "",
} as any);
const route = useRoute();

//区分是创建还是更新页面,因为更新和创建共用一个页面，以显示区分提交接口
const updatePage = route.path.includes("update");

const loadDate = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const res = await QuestionControllerService.getQuestionByIdUsingGet(
    id as any
  );
  if (res.code === 0) {
    form.value = res.data;
    if (!res.data?.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags);
    }
    if (!res.data?.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase);
    }
    if (!res.data?.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 0,
        stackLimit: 0,
        timeLimit: 0,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig);
    }
  } else {
    message.error("加载失败 " + res.message);
  }
};
/**
 * 加载修改数据
 */
onMounted(() => {
  loadDate();
});

/**
 * 添加测试用例
 */
const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};
/**
 * 删除测试用例
 * @param index
 */
const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};

const onContentChange = (content: string) => {
  form.value.content = content;
};

const onAnswerChange = (answer: string) => {
  form.value.answer = answer;
};
import { useRouter } from "vue-router";
import { QuestionControllerService } from "../../../generator";

const router = useRouter();
//提交表单,区分更新和创建页面
const handleSubmit = async () => {
  console.log(form);
  if (updatePage) {
    const res = await QuestionControllerService.updateQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("更新成功!");
      router.push({
        path: "/question/manage",
      });
    } else {
      message.error("更新失败 " + res.message);
    }
  } else {
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("创建成功!");
    } else {
      message.error("创建失败 " + res.message);
    }
  }
};
</script>

<style scoped>
#addQuestionView {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
