<template>
  <div id="questionSubmitView">
    <a-form :model="searchParams" :layout="'inline'">
      <a-form-item field="title" label="题目名称">
        <a-input
          v-model="searchParams.questionId"
          placeholder="请输入题号"
          style="min-width: 240px"
        />
      </a-form-item>
      <a-form-item field="language" label="编程语言">
        <a-select
          v-model="searchParams.language"
          :style="{ width: '320px' }"
          placeholder="选择编程语言"
        >
          <a-option>java</a-option>
          <a-option>cpp</a-option>
          <a-option>go</a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSearch">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: total,
        showTotal: true,
      }"
      @page-change="onPageChange"
    >
      <template #status="{ record }">
        <a-space wrap>
          <a-tag :color="colors[record.status]"
            >{{
              record.status === 0
                ? "等待中"
                : record.status === 1
                ? "判题中"
                : record.status === 2
                ? "成功"
                : "失败"
            }}
          </a-tag>
        </a-space>
      </template>
      <template #judgeInfo="{ record }">
        <a-space wrap>
          <div
            v-for="(value, key) of JSON.parse(JSON.stringify(record.judgeInfo))"
            :key="key"
          >
            {{ key }}:
            <a-space wrap v-if="key === 'message'">
              <a-tag v-if="value === 'ACCEPT'" color="green">
                {{ value }}
              </a-tag>
              <a-tag v-else-if="value === 'WRONG_ANSWER'" color="red">
                {{ value }}
              </a-tag>
              <a-tag v-else color="orangered"> {{ value }}</a-tag>
            </a-space>
            <a-space wrap v-else>
              <a-tag color="blue"> {{ value }}</a-tag>
            </a-space>
          </div>
        </a-space>
      </template>
      <template #createTime="{ record }"
        >{{ moment(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
  QuestionSubmitQueryRequest,
} from "../../../generator";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";

const dataList = ref([]);
const total = ref(0);
let searchParams = ref<QuestionSubmitQueryRequest>({
  questionId: undefined,
  language: undefined,
  pageSize: 10,
  current: 1,
});
const loadData = async () => {
  const res = await QuestionControllerService.listQuestionSubmitByPageUsingPost(
    {
      //按时间降序排序
      ...searchParams.value,
      sortField: "createTime",
      sortOrder: "descend",
    }
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
    console.log(res.data);
  } else {
    message.error("加载失败 " + res.message);
  }
};
/**
 * 页面加载时获取题目数据
 */
onMounted(() => {
  loadData();
});

const colors = ["gray", "blue", "green", "red"];

const columns = [
  {
    title: "提交id",
    dataIndex: "id",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "判题结果",
    slotName: "judgeInfo",
  },
  {
    title: "题目id",
    dataIndex: "questionId",
  },
  {
    title: "提交者id",
    dataIndex: "userId",
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
];
/**
 * 做题
 */
const router = useRouter();

/**
 * 该函数监听其传入函数内属性值的变化，一旦发生变化就会重新触发该函数
 */
watchEffect(() => {
  loadData();
});

/**
 * 搜索题目
 */
const doSearch = () => {
  //先将当前页改为1,避免搜索后的数据显示不出来（比如搜索出来的数据可能没有第二页）
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
  //数据改变后就会触发loadData，因为它被监听了
};

//分页
const onPageChange = (current: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: current,
  };
};
</script>

<style scoped>
#questionSubmitView {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
