<template>
  <a-typography-title :heading="6"> 请发表友善的评论~</a-typography-title>
  <a-comment align="right" :avatar="store.state.user?.loginUser?.userAvatar">
    <a-space wrap>
      <a-input
        style="min-width: 500px"
        placeholder="Here is you content."
        v-model="addCommentParams.content"
      />
      <a-button type="primary" size="medium" @click="addComment()">
        评论
      </a-button>
    </a-space>
  </a-comment>
  <a-form-item v-for="(comment, index) of dataListTemp" :key="index" no-style>
    <a-comment
      :author="comment.user.userName"
      :content="comment.content"
      :datetime="timeChange(comment.createTime)"
    >
      <template #actions>
        <span class="action" key="heart" @click="onCommentChange(comment)">
          <span v-if="comment">
            <IconHeartFill :style="{ color: '#f53f3f' }" />
          </span>
          {{ comment.thumbNum }}
        </span>
        <span class="action" key="reply">
          <IconMessage /><a-button
            type="text"
            size="mini"
            @click="handleReplyClick(comment)"
            >回复</a-button
          >
          <a-modal
            v-model:visible="visibleReply"
            @ok="handleReplyOk(comment)"
            @cancel="handleReplyCancel"
          >
            <template #title> 回复 </template>
            <div>
              <a-typography-title :heading="6">
                请发表友善的评论~</a-typography-title
              >
              <a-input
                style="min-width: 500px"
                :placeholder="`回复给:${respondUserName}`"
                v-model="addCommentParams.content"
              />
            </div>
          </a-modal>
        </span>
        <span class="action">
          <a-button
            type="text"
            status="normal"
            size="mini"
            @click="handleClick(comment)"
            ><a-tag color="blue">{{ comment.tags.length }}</a-tag
            >详情
          </a-button>

          <a-drawer
            :width="340"
            :height="340"
            :visible="visible"
            :placement="position"
            @ok="handleOk(comment)"
            @cancel="handleCancel"
            unmountOnClose
          >
            <template #title> 回复详情</template>
            <a-form-item
              v-for="(comment1, index) of relatedDataList"
              :key="index"
              no-style
            >
              <template v-if="comment1">
                <a-comment
                  :author="comment1.user.userName"
                  :content="comment1.content"
                  :datetime="timeChange(comment1.createTime)"
                >
                  <template #actions>
                    <span
                      class="action"
                      key="heart"
                      @click="onCommentChange(comment1)"
                    >
                      <span v-if="comment1">
                        <IconHeartFill :style="{ color: '#f53f3f' }" />
                      </span>
                      {{ comment1.thumbNum }}
                    </span>
                  </template>
                  <template #avatar>
                    <a-avatar>
                      <img alt="avatar" :src="comment1.user.userAvatar" />
                    </a-avatar>
                  </template>
                </a-comment>
              </template>
              <template v-else>当前还无人回复你哦~</template>
            </a-form-item>
          </a-drawer>
        </span>
      </template>
      <template #avatar>
        <a-avatar>
          <img alt="avatar" :src="comment.user.userAvatar" />
        </a-avatar>
      </template>
    </a-comment>
  </a-form-item>
  <a-space direction="vertical" size="large">
    <a-pagination
      :total="total"
      :current="searchParams.current"
      :pageSize="searchParams.pageSize"
      size="medium"
      show-total
      show-jumper
      @change="onPageChange"
    />
  </a-space>
</template>

<script setup lang="ts">
import {
  IconHeart,
  IconMessage,
  IconHeartFill,
} from "@arco-design/web-vue/es/icon";

import message from "@arco-design/web-vue/es/message";
import { onMounted, ref, watchEffect, nextTick } from "vue";
import {
  CommentAddRequest,
  CommentControllerService,
  CommentQueryRequest,
  CommentUpdateRequest,
} from "../../../generator";
import { useRoute } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";
import BigNumber from "bignumber.js";
//引入全局变量
const store = useStore();

//评论展示数据
const dataList = ref([]);
const total = ref(0);
const dataListTemp = ref([]);
//去除掉是回复他人的评论ids
let response = ref([]);

const route = useRoute();
const path = route.path;
const qs = path.split("/").pop();
console.log(path);
console.log(qs);
const questionId = new BigNumber(qs);

console.log(questionId);

let searchParams = ref<CommentQueryRequest>({
  content: "",
  tags: [],
  pageSize: 20,
  current: 1,
  questionId: questionId,
});

//获取评论信息
const loadData = async () => {
  const res = await CommentControllerService.listCommentVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    dataList.value = res.data.records;

    total.value = res.data.total;
    console.log(res.data);
    console.log(response.value, "是回复的评论");
  } else {
    message.error("加载失败 " + res.message);
  }
};
//监听返回的datalist的变化
watchEffect(() => {
  dataList.value = dataList.value.filter((c) => {
    if (c.tags.length !== 0) {
      for (let i = 0; i < c.tags.length; i++) {
        response.value.push(c.tags[i]);
      }
    }
    return c;
  });
  dataListTemp.value = dataList.value.filter((c) => {
    if (response.value.indexOf(c.id) === -1) {
      return c;
    }
  });
});

//修改评论
const like = ref(false);
const updateParams = ref<CommentUpdateRequest>({
  id: 0,
  thumbNum: 0,
  tags: [],
});
const onCommentChange = async (comment: any) => {
  like.value = !like.value;
  updateParams.value.id = comment.id;
  updateParams.value.thumbNum = comment.thumbNum + 1;
  updateParams.value.tags = comment.tags;
  const res = await CommentControllerService.updateCommentUsingPost(
    updateParams.value
  );
  if (res.code === 0) {
    loadData();
  } else {
    message.error("点赞失败 " + res.message);
  }
};

let addCommentParams = ref<CommentAddRequest>({
  content: "",
  tags: [],
  userId: store.state.user.id,
  thumbNum: 0,
  questionId: questionId,
  resCommentId: undefined,
});
//添加评论
const addComment = async () => {
  const res = await CommentControllerService.addCommentUsingPost(
    addCommentParams.value
  );
  if (res.code == 0) {
    loadData();
    addCommentParams.value.content = "";
    addCommentParams.value.resCommentId = undefined;
    message.success("评论成功!");
  } else {
    message.error("评论失败 " + res.message);
  }
};
/**
 * 使用 handleReplyClick 和 handleReplyOk 函数，并向这两个函数传递了 comment 参数。如果两个函数得到的 comment.id 不一样，可能有几种原因：
 * 异步操作：
 * 如果 handleReplyClick 或 handleReplyOk 中包含了异步操作（例如，Promise、回调函数、异步请求等），那么在它们执行时，可能 comment 对象已经发生了变化。
 * 确保在调用这两个函数之前，没有对 comment 进行修改或重新赋值的操作。
 * 在这里，commentId 是一个独立的变量，保存了 comment.id 的值。确保在 handleReplyOk 中能够访问到正确的 commentId
 *
 * 个人觉得：
 * 这是在 Vue.js 组件中，Vue.js 在响应式数据变化时可能会存在异步更新的情况。确保在函数调用时，Vue.js 已经完成了对 comment 对象的更新。
 * 也就是打开评论窗口会改变comment对象的引用
 */
//添加回复
const visibleReply = ref(false);
let commentId = 0;
let respondUserName = "";
const handleReplyClick = (comment: any) => {
  visibleReply.value = true;
  commentId = comment.id;
  respondUserName = comment.user.userName;
  console.log(comment.id);
};
const handleReplyOk = (comment: any) => {
  visibleReply.value = false;
  console.log(commentId);
  addCommentParams.value.resCommentId = commentId;
  console.log(addCommentParams.value.resCommentId);
  addComment();
};
const handleReplyCancel = () => {
  visibleReply.value = false;
  addCommentParams.value.content = "";
};

//分页
const onPageChange = (current: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: current,
  };
};

/**
 * 该函数监听其传入函数内属性值的变化，一旦发生变化就会重新触发该函数
 */
watchEffect(() => {
  loadData();
});

const timeChange = (time: string) => {
  var format = moment(time).format(" YYYY-MM-DD HH:mm:ss");
  return format;
};
/**
 * 页面加载时获取题目数据
 */
onMounted(() => {
  loadData();
});

//处理评论详情
let relatedDataList = ref([]);
const visible = ref(false);
const position = ref("left");

const handleClick = (comment: any) => {
  visible.value = true;
  console.log("返回的全部评论", dataList.value);
  watchEffect(() => {
    relatedDataList.value = dataList.value.filter((c) => {
      if (comment.tags.indexOf(c.id) !== -1) {
        return c;
      }
    });
  });

  console.log("评论详情", relatedDataList.value);
};

const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};
</script>
<style scoped>
.action {
  display: inline-block;
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}
</style>
