<template>
  <div
    id="code-editor"
    ref="codeEditorRef"
    style="min-height: 600px; height: 50vh"
  />
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults, toRaw, onMounted, watch } from "vue";
import * as monaco from "monaco-editor";

/**
 * 定义组件属性类型，让父组件操控Editor组件以收集输入信息返回给后端
 */
interface Props {
  value: string;
  language: string;
  handleChange: (v: string) => void;
}

/**
 * 给组件指定默认初始值
 */
const props = withDefaults(defineProps<Props>(), {
  value: () => "",
  language: () => "java",
  handleChange: (v: string) => {
    console.log(v);
  },
});

const codeEditorRef = ref();
const codeEditor = ref();

/**
 * 当编程语言变化时，也就是切换了编程语言，我们要检测到然后重新将该值赋给代码编辑器
 */
watch(
  () => props.language,
  () => {
    if (codeEditor.value) {
      monaco.editor.setModelLanguage(
        toRaw(codeEditor.value).getModel(),
        props.language
      );
    }
  }
);

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  codeEditor.value = monaco.editor.create(codeEditorRef.value, {
    value: props.value,
    language: props.language,
    colorDecorators: true,
    automaticLayout: true,
    minimap: {
      enabled: true,
    },
    readOnly: false,
    theme: "vs-dark",
  });
  //编辑监听内容变化
  codeEditor.value.onDidChangeModelContent(() => {
    props.handleChange(toRaw(codeEditor.value).getValue());
  });
});
</script>

<style scoped></style>
