<template>
  <div id="viewQuestionView">
    <a-row :gutter="[24, 24]">
      <a-col :md="12" :xs="24">
        <!--        默认激活的标签页-->
        <a-scrollbar style="height: 80vh; overflow: auto">
          <a-tabs default-active-key="question">
            <a-tab-pane key="question" title="题目">
              <a-card v-if="question" :title="question.title">
                <a-descriptions
                  title="判题条件"
                  :column="{ xs: 1, md: 2, lg: 3 }"
                >
                  <a-descriptions-item label="时间限制">
                    {{ question.judgeConfig.timeLimit ?? 0 }}
                  </a-descriptions-item>
                  <a-descriptions-item label="内存限制">
                    {{ question.judgeConfig.memoryLimit ?? 0 }}
                  </a-descriptions-item>
                  <a-descriptions-item label="堆栈限制">
                    {{ question.judgeConfig.stackLimit ?? 0 }}
                  </a-descriptions-item>
                </a-descriptions>
                <MdViewer :value="question.content" />
                <template #extra>
                  <a-space wrap>
                    <a-tag
                      v-for="(tag, index) of question.tags"
                      :key="index"
                      :color="colors[tag]"
                      size="large"
                      >{{ tag }}
                    </a-tag>
                  </a-space>
                </template>
              </a-card>
            </a-tab-pane>
            <a-tab-pane key="answer" title="题解">
              <a-card v-if="question" :title="question.title">
                <template #extra>
                  <a-space wrap>
                    <a-tag
                      v-for="(tag, index) of question.tags"
                      :key="index"
                      :color="colors[tag]"
                      size="large"
                      >{{ tag }}
                    </a-tag>
                  </a-space>
                </template>
                <MdViewer :value="question.answer" />
              </a-card>
            </a-tab-pane>
            <a-tab-pane key="comment" title="评论">
              <CommentView />
            </a-tab-pane>
          </a-tabs>
        </a-scrollbar>
      </a-col>

      <a-col :md="12" :xs="24">
        <a-scrollbar style="height: 80vh; overflow: auto">
          <a-form :model="form" :layout="'inline'">
            <a-form-item field="language" label="编程语言">
              <a-select
                v-model="form.language"
                :style="{ width: '320px' }"
                placeholder="选择编程语言"
              >
                <a-option>java</a-option>
                <a-option>cpp</a-option>
                <a-option>go</a-option>
              </a-select>
            </a-form-item>
          </a-form>
          <!--        监听语言与代码-->
          <CodeEditor
            :value="form.code"
            :language="form.language"
            :handle-change="changeCode"
          />
          <a-divider size="0" />
          <a-button type="primary" size="large" shape="square" @click="doSubmit"
            >提交
          </a-button>
        </a-scrollbar>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, onMounted, ref } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../generator";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import CommentView from "@/views/comment/CommentView.vue";

const question = ref<QuestionVO>();

/**
 * 获取到路由的props id信息
 */
interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

//获取题目信息
const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
    console.log(res.data);
  } else {
    message.error("加载失败 " + res.message);
  }
};
/**
 * 页面加载时获取题目数据
 */
onMounted(() => {
  loadData();
});
const colors = {
  简单: "green",
  适中: "orange",
  困难: "red",
};

/**
 * 用于提交的数据
 */
const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: "",
});

/**
 * 将代码填到表单中
 */
const changeCode = (value: string) => {
  form.value.code = value;
};

/**
 * 提交代码
 */
const doSubmit = async () => {
  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value?.id,
  });
  if (res.code === 0) {
    message.success("提交成功");
  } else {
    message.error("提交失败 " + res.message);
  }
};
</script>

<style scoped>
#viewQuestionView {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
